import { FC, useMemo } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { appInsights } from '../api/AppInsights'
import Page from '../components/Page'
import { AppVersion, Secret } from '../stores/AppStore'

import Style from '../styles/Home.module.sass'

const Home: FC = () => {
  const secret = useRecoilValueLoadable(Secret)
  const version = useRecoilValueLoadable(AppVersion)

  const secretValue = useMemo(() => {
    if (secret.state !== 'hasValue' || !secret.contents) return null
    return secret.contents
  }, [secret])

  const versionValue = useMemo(() => {
    if (version.state !== 'hasValue' || !version.contents) return null
    return version.contents
  }, [version])

  if (appInsights) appInsights.trackPageView({ name: 'Home' })

  console.log(secretValue)

  return (
    <Page>
      <h1>Home</h1>
      <h3>Welcome to the reactJS + FastAPI template.</h3>

      {versionValue && <p>Version: {versionValue}</p>}

      <p className={Style.text}>Some links</p>

      <p>
        Profile: <a href='/#/profile'>here</a>
      </p>
      <p>
        Terms and Conditions: <a href='/#/termsandconditions'>here</a>
      </p>
      <p>
        Privacy: <a href='/#/privacy'>here</a>
      </p>
      <p>
        Cookies: <a href='/#/cookies'>here</a>
      </p>
      <p className={Style.text}>NR trial build</p>
    </Page>
  )
}

export default Home
