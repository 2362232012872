import React, { FC } from 'react'
import { Container } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/Page.module.sass'

export interface IPageProps {
  children?: JSX.Element[] | JSX.Element | React.ReactNode
}

const Page: FC<IPageProps> = (props) => {
  return (
    <div className={Style.page}>
      <Container cssClass={Style.container}>{props.children}</Container>
    </div>
  )
}

export default Page
