import { IResponse } from '../models/api/IResponse'

export interface IAppVersion {
  version: string
}

export interface IAppFlags {
  [x: string]: boolean
}

const FAIL_VERSION_RESPONSE = { success: false, data: { version: '0.0.0' } }
const FAIL_FLAGS_RESPONSE = { success: false, data: {} }

export const getAppVersion = async (): Promise<IResponse<IAppVersion>> => {
  try {
    const response = await fetch('/v1/app/version')

    if (!response.ok) return FAIL_VERSION_RESPONSE
    const data = await response.json()
    return { success: true, data }
  } catch (err) {
    console.error(err)
    return FAIL_VERSION_RESPONSE
  }
}

export const getAppFlags = async (): Promise<IResponse<IAppFlags>> => {
  try {
    const response = await fetch('/v1/app/flags')
    if (response.ok) return await response.json()

    return FAIL_FLAGS_RESPONSE
  } catch {
    return FAIL_FLAGS_RESPONSE
  }
}

export interface IFocusData {
  [x: string]: { label: string; options: string[] }
}
