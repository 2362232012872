import { selector, atom } from 'recoil'
import { getAppFlags, getAppVersion } from '../api/AppService'
import { getSecret } from '../api/ProtectedService'

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = await getAppVersion()
    return response.data?.version
  },
})

export const AppFlags = selector({
  key: 'app_flags',
  get: async () => {
    const response = await getAppFlags()
    return response.data || {}
  },
})

export const Secret = selector({
  key: 'secret',
  get: async () => {
    const response = await getSecret()
    return response || {}
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const NotFound = atom<boolean>({
  key: 'not_found',
  default: false,
})
